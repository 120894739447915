<template>
    
    <v-card>
        <s-toolbar  
            color="primary" 
            dark 
            label="Listados de Tarimas en enfriamiento" >
        </s-toolbar>
        <v-container>
            <v-row>
                <v-col cols="12" md="2">
                    <!---->
                    <s-select-definition 
                        label="Tipo Calibre"
                        :dgr="34"
                        :def="1266"
                        v-model="typeCaliber"
                        clearable/>    
                </v-col>
                <v-col cols="12" md="3">
                    <!---->
                    <s-select-definition 
                        label="Tipo Cultivo"
                         
                        :def="1172"
                        v-model="typeCrop"                    
                        clearable/>    
                </v-col>
                <v-col cols="12" md="3">
                    <s-select-type-certification
                        clearable
                        noDefault
                        label="Certificacion"
                        v-model="TcrID"                
                    ></s-select-type-certification>
                </v-col>
                <v-col cols="12" md="2"  >
                    <s-select-definition
                    :def="1181"
                    :dgr="74"
                    label="Destino"
                    v-model="TypeDestiny"
                    clearable
                    /> 
                </v-col>            
                <v-col cols="12" md="2" align-self="end">
                    <!-- <template> -->
                        <v-btn  value="right"
                            :color="'primary'"
                            @click="buscar()"
                           
                            >BUSCAR
                        <!-- <span class="hidden-sm-and-down">BUSCAR</span> 
                            <i class="fas fa-search"></i> -->
                        </v-btn>
                    <!-- </template> -->
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-data-table
						dense
						:items="ItemLytDetail"
						:headers="headerItemLytDetail"
						:items-per-page="-1"
						hide-default-footer
						desc
                        :loading="loading"
                        group-by="TnkNumberTreatment"
					>
                        
						<template v-slot:item.Time="{ item }">							 							   
                              {{item.time}}                                 
							 <v-chip
                                dark
                                small
                                :color="item.IsCold  ? 'error' : 'cyan'">                                
                                <i :class="item.IsCold  ? 'fas fa-fire' : 'fas fa-cube'"></i>
                                </v-chip>
                                 
						</template>
                        <template v-slot:item.accion="{ item }">
							<v-btn
								depressed
								color="primary"
								small
								@click="pickUpLayette(item)">
								<i class="fas fa-truck-pickup"></i>
							</v-btn>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
        </v-container>
    </v-card>

</template>
<script>

    import _sLayetteService from "@/services/FreshProduction/PrfLayetteService.js";
    import sSelectTypeCertification from "@/components/Utils/Certification/sSelectTypeCertification.vue";

    export default {
        components: {
            sSelectTypeCertification
		},
        data: () => ({
            TcrID: null,
            typeCrop: null,
            typeCaliber: null,
            loading: true,
            TypeDestiny: null,
            itemtime: null,
            ItemLytDetail: [],
            headerItemLytDetail: [
               
                { text: "Enfriamiento", value: "Time" },
                { text: "Hora Frio", value: "DateEndCooling" },
                { text: "Cod. Palet", value: "LlpID" },
                { text: "Cant Jabas", value: "LlpQuantityJaba"},
                { text: "Cultivo", value: "TypeCultiveName" },
                { text: "Calibre", value: "TypeCaliberName" },
                { text: "Lote Producción", value: "RcfCode"  },
                { text: "Certificacion", value: "TypeCertificationName" },
                { text: "Destiono", value: "TypeDestinyName" },
                { text: "T. Cultivo", value: "TypeCropName" },
                { text: "Acción", value: "accion" }, 
                { text: "# Tratamiento", value: "TnkNumberTreatment" },
            ],
            /* , width: 120*/
        }),
        watch: {
            ItemLytDetail(){
                console.log("cambio");
            }
        },
        methods: {
 
            buscar(){
                console.log("Busqueda Prueba22");
                this.getlytDetailTableonfloor();
            },

            getlytDetailTableonfloor(){
                this.loading= true;
                //console.log("this.TcrID", this.TcrID);
                let filter = {
                    TypeCaliber:    this.typeCaliber,
                    TypeCrop:       this.typeCrop,
                    TcrID:          this.TcrID,
                    TypeDestiny :   this.TypeDestiny                
                }
                console.log("Filtro", filter);
                _sLayetteService.lytDetailTableonfloor(filter, this.$fun.getUserID())
                .then((r) => {
                    if(r.status == 200){
                        console.log("r data => details", r.data);
                        this.ItemLytDetail = r.data
                        this.loading= false;
                    }
                });
            },
            pickUpLayette(item)
            {
                this.$fun.alert('Seguro de recoger?', 'question')
                .then(resp => {
                    if(resp.value){
                          
                        let obj = {
                            LytID : item.LytID,
                            LlpID : item.LlpID
                        }
                        console.log('objj ', item);

                        _sLayetteService.lytonfloorPickUp(obj, this.$fun.getUserID())
                        .then((r) => {
                            if(r.status == 200){
                                 this.getlytDetailTableonfloor();
                            }
                        }); 
                        
                         
                    }
                });
            },
            msToTime(duration) {
                var milliseconds = parseInt((duration % 1000) / 100),
                    seconds = Math.floor((duration / 1000) % 60),
                    minutes = Math.floor((duration / (1000 * 60)) % 60),
                    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

                hours = (hours < 10) ? "0" + hours : hours;
                minutes = (minutes < 10) ? "0" + minutes : minutes;
                seconds = (seconds < 10) ? "0" + seconds : seconds;

                //return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
                return hours + ":" + minutes + ":" + seconds;
            },
            changeHour(){
                let time = this;
                this.nIntervId = setInterval(()=>{
                    

                    this.ItemLytDetail.forEach(element => {
                        
                        let count = Math.abs((new Date().getTime())-(new Date(element.DateEndCooling).getTime())) ;

                        if ( (new Date()) >= (new Date(element.DateEndCooling))) {
                        // time.layette.TypeCoolingStatusIcon = "fas fa-cube";
                            //time.layette.TypeCoolingStatusColor = "cyan";
                            element.time = "00:00:00";
                        }else{
                            element.time = time.msToTime(count)
                        }
                        console.log("time.countHour", element.time)
                    });
                }, 1000);			
		},
        },
        mounted() {
            this.itemtime = new Date();
            this.getlytDetailTableonfloor();
            this.changeHour();
        },
        beforeDestroy(){
            clearInterval(this.nIntervId);
            // liberar nuestro inervalId de la variable
            this.nIntervId = null;
        }
    }

</script>